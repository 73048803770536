const {v4} = require("uuid");
let isLoading = false
let loadingEle = null
let toastBody = null
function getClientId() {
    let clientId = localStorage.getItem('clientId')
    if (clientId) return clientId
    clientId = v4()
    localStorage.setItem('clientId', clientId)
    return clientId
}

function showToast(text) {
    if (toastBody) {
        document.body.removeChild(toastBody)
        toastBody = null
    }
    toastBody = document.createElement("div");
    toastBody.classList.add('sl-toast');
    toastBody.innerText = text
    document.body.append(toastBody)
    const timeoutID = window.setTimeout(() => {
        if (toastBody) {
            document.body.removeChild(toastBody)
            toastBody = null
        }
        window.clearTimeout(timeoutID)
    }, 3000);
}

function showLoading () {
    if (isLoading === false) {
        const loadingContainer = document.createElement("div")
        loadingContainer.classList.add('loader-container')
        let loadingBody = document.createElement("div")
        loadingBody.classList.add('loader');
        loadingContainer.append(loadingBody)
        // toastBody.innerText = text
        document.body.append(loadingContainer)
        isLoading = true
        loadingEle = loadingContainer
    }

}

function startDownload (url) {
    let a = document.createElement('a');
    a.setAttribute('download', '');// download属性
    a.setAttribute('href', url);// href链接
    a.click();// 自执行点击事件
    a = null;
}

function hideLoading () {
    if (loadingEle) {
        document.body.removeChild(loadingEle)
        isLoading = false
        loadingEle = null
    }
}

const MainMenuType = {
    Home: 'HOME',
    Download: 'DOWNLOAD',
    Price: 'PRICE',
    UserComment: 'USERCOMMENT',
    Login: 'LOGIN'
};

function checkIfLocal() {
    return window.location.href.indexOf('127.0.0.1') >= 0 || window.location.href.indexOf('localhost') >= 0
}

function getUrlType() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get('type')
    return type
}

function getUrlId() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get('id')
    return type
}

function getUrlParamByName(name) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(name)
}

function verifyEmail(email) {
    var reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
    if (email.value === "") {
        return false;
    }
    return reg.test(email);
}

function getUrlWithoutParam() {
    return window.location.protocol + '//' + window.location.host + window.location.pathname
}

function roundNum(num, decimal = 2) {
    return Math.round(num * 10 ** decimal) / (10 ** decimal)
}

function copyTextToClipboard(text) {
    if (window.Clipboard && isMobile()) {
        window.Clipboard.copy(text);
    } else {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }
    showToast('已复制福利码', 3, 'success')
}

function isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    }
    return false
}

function fallbackCopyTextToClipboard(text) {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";


    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");

    try {
        let successful = document.execCommand('copy');
        let msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

module.exports = {
    getClientId,
    showToast,
    MainMenuType,
    checkIfLocal,
    getUrlType,
    getUrlId,
    getUrlWithoutParam,
    verifyEmail,
    roundNum,
    copyTextToClipboard,
    uuidv4,
    showLoading,
    hideLoading,
    startDownload,
    getUrlParamByName
};