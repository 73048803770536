const serverUrl = 'https://payment.private-tunnel.com:3099/'
const isTest = false
const minWithdrawDividend = 5
const vcodeCountdown = 60
module.exports = {
    serverUrl,
    isTest,
    minWithdrawDividend,
    vcodeCountdown
};
