import './App.css';
import StaticMain from './static/StaticMain'

function App() {
	return (
		<StaticMain/>
	);
}

export default App;
